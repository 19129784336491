<template>
  <div id="profile-wrapper">
    <v-row no-gutters>
      <v-col cols="1" md="0" sm="0" class="left-spacer" />
      <v-col cols="12" class="user-page-title main-content">
        <span>Update User Profile</span>
      </v-col>
      <v-col cols="12" md="0" sm="0" />
    </v-row>
    <v-row no-gutters>
      <v-col cols="1" md="0" sm="0" class="left-spacer" />
      <v-col cols="12" class="main-content">
        <v-form @submit.prevent="submit" v-model="valid">
          <v-row no-gutters
            ><v-col cols="12" md="12" class="user-page-subtitle"
              ><span>Authentication Details</span></v-col
            ></v-row
          >
          <v-row id="top-form" class="box-height1" no-gutters>
            <v-col cols="3" md="3" sm="12" class="first-col">
              <v-row class="fill-height background-opac border" no-gutters>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6" md="12" lg="6" sm="12" class="justify-center">
                      <v-file-input
                        v-model="avatarImage"
                        label="Image"
                        required
                        @change="onFileChange"
                        ref="file"
                        accept="image/png, image/jpeg, image/jpg"
                        :rules="fileRule"
                        style="display: none"
                      ></v-file-input>
                      <v-avatar>
                        <v-img
                          alt="Profile Picture"
                          contain
                          src="../../assets/pages/icon_user_default_display.svg"
                          transition="scale-transition"
                          v-if="!profileImage"
                          max-height="114"
                          max-width="114"
                          style="margin: auto"
                        />
                        <img
                          v-else
                          alt="Profile Picture"
                          contain
                          :src="profileImage"
                          transition="scale-transition"
                          max-height="114"
                          max-width="114"
                          style="margin: auto"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="6" md="12" lg="6" sm="12" class="avatar-btn d-flex">
                      <v-row align="center">
                        <v-col
                          cols="6"
                          md="6"
                          lg="6"
                          sm="6"
                          xl="3"
                          class="no-padding d-flex file-change-btn"
                        >
                          <v-btn
                            :ripple="false"
                            class="form-btn"
                            @click="triggerFileChange"
                          >
                            Change
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="6"
                          md="6"
                          lg="6"
                          sm="6"
                          class="no-padding d-flex justify-center"
                        >
                          <v-btn
                            :ripple="false"
                            class="form-btn"
                            :disabled="!valid"
                            @click="submit"
                          >
                            Save
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="status">
                    <v-col cols="6" md="6" lg="6">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p>Last Logged In</p>
                        </v-col>
                        <v-col cols="12">
                          <p class="color-green">{{ lastLogin }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" md="6" lg="6">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p>Last Device Logged In</p>
                        </v-col>
                        <v-col cols="12">
                          <p class="color-green">{{ deviceLogin }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" md="3" sm="12" class="second-col">
              <v-row no-gutters class="fill-height">
                <v-col cols="12">
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters><p>Full Name</p></v-row>
                      <v-row no-gutters
                        ><v-text-field
                          v-model="userName"
                          :rules="userNameRules"
                          filled
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters> <p>Role and Deparment</p> </v-row>
                      <v-row no-gutters>
                        <span>{{ userGroup }}</span>
                      </v-row>
                      <v-row no-gutters>
                        <span class="color-green">{{ userSite }}</span>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" md="3" sm="12" class="third-col">
              <v-row no-gutters class="fill-height">
                <v-col cols="12">
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters> <p>Email Address</p> </v-row>
                      <v-row no-gutters>
                        <p>{{ emailAddress }}</p>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters> <p>Password</p> </v-row>
                      <v-row no-gutters align="center">
                        <v-col cols="3" md="3" sm="3" style="padding-top: 8px">
                          <span class="hidden-pass">******</span>
                        </v-col>
                        <v-col cols="9" md="9" sm="9" class="btn-change-password">
                          <v-btn :ripple="false" class="form-btn" @click="openDialog">
                            Change Password
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" md="3" sm="12" class="fourth-col">
              <v-row no-gutters class="fill-height">
                <v-col cols="12">
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters><p>Phone Number</p></v-row>
                      <v-row no-gutters>
                        <p>{{ phoneNum }}</p>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="half-height background-opac border">
                    <v-col cols="12">
                      <v-row no-gutters> <p>Timezone</p> </v-row>
                      <v-row no-gutters>
                        <v-col cols="12"
                          ><span>GMT/UTC {{ timeZoneOffset }} hours</span>
                        </v-col>
                        <v-col cols="12">
                          <span>{{ timeZoneName }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters
            ><v-col cols="12" md="12" class="user-page-subtitle"
              ><span>Notifications</span></v-col
            ></v-row
          >

          <v-row id="bot-form22" no-gutters class="bot-box-height1">
            <v-col cols="4" md="4" sm="12" class="notification-via">
              <v-row class="fill-height background-opac border" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters class="noti-title">
                    <p>Notify me via</p>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row no-gutters class="line">
                        <v-col cols="8" md="8" sm="8" class="">
                          <p>On Screen Notifications</p>
                        </v-col>
                        <v-col cols="4" md="4" sm="4">
                          <v-switch
                            v-model="user_settings.on_screen_notifications"
                            :label="user_settings.on_screen_notifications ? 'Yes' : 'No'"
                            inset
                            :ripple="false"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="line">
                        <v-col cols="8" md="8">
                          <p>Email Notifications</p>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-switch
                            v-model="user_settings.email_notifications"
                            :label="user_settings.email_notifications ? 'Yes' : 'No'"
                            class="mt-0"
                            inset
                            :ripple="false"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="8" md="8" class="notification-via">
                          <p>SMS Notifications</p>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-switch
                            v-model="user_settings.sms_notifications"
                            :label="user_settings.sms_notifications ? 'Yes' : 'No'"
                            class="mt-0"
                            inset
                            :ripple="false"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" md="4" sm="12" class="notification-for">
              <v-row class="fill-height background-opac border" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters class="noti-title">
                    <p>Notify me for</p>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row>
                        <v-radio-group v-model="user_settings.notify_me_for">
                          <v-radio
                            label="Everything"
                            value="everything"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="New Alarm Triggers"
                            value="new_alarm_triggers"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="New Alarm Escalations"
                            value="new_alarm_escalations"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="Grid Service Requests (W/DR)"
                            value="grid_services_requests"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" md="4" sm="12" class="notification-send">
              <v-row class="fill-height background-opac border" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters class="noti-title">
                    <p>Send me notifications</p>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row>
                        <v-radio-group v-model="user_settings.send_me_notifications">
                          <v-radio
                            label="On New Event"
                            value="on_new_event"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="Daily"
                            value="daily"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="Weekly"
                            value="weekly"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                          <v-radio
                            label="Monthly"
                            value="monthly"
                            color="#61bfeb"
                            :ripple="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row id="bot-form" no-gutters class="bot-box-height1" style="display: none">
            <v-col cols="12" md="4" class="bot-box-height background-opac">
              <v-card
                class="bot-box-height"
                outlined
                tile
                style="border-right-color: #1993c9"
              >
                <v-card-text class="card-5">
                  <v-row no-gutters class="label-row"
                    ><v-col cols="12" md="12"><p>Notify me via</p></v-col></v-row
                  >
                  <v-row no-gutters class="line">
                    <v-col cols="12" md="8" class="notification-via">
                      <p>On Screen Notifications</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch
                        v-model="user_settings.on_screen_notifications"
                        :label="user_settings.on_screen_notifications ? 'Yes' : 'No'"
                        class="mt-0"
                        inset
                        :ripple="false"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="line">
                    <v-col cols="12" md="8" class="notification-via">
                      <p>Email Notifications</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch
                        v-model="user_settings.email_notifications"
                        :label="user_settings.email_notifications ? 'Yes' : 'No'"
                        class="mt-0"
                        inset
                        :ripple="false"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="8" class="notification-via">
                      <p>SMS Notifications</p>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-switch
                        v-model="user_settings.sms_notifications"
                        :label="user_settings.sms_notifications ? 'Yes' : 'No'"
                        class="mt-0"
                        inset
                        :ripple="false"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="bot-box-height background-opac">
              <v-card
                class="bot-box-height"
                outlined
                tile
                style="border-left-color: #1993c9; border-right-color: #1993c9"
              >
                <v-card-text class="card-6">
                  <p>Notify me for</p>
                  <v-radio-group v-model="user_settings.notify_me_for">
                    <v-radio
                      label="Everything"
                      value="everything"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="New Alarm Triggers"
                      value="new_alarm_triggers"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="New Alarm Escalations"
                      value="new_alarm_escalations"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="Grid Service Requests (W/DR)"
                      value="grid_services_requests"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="bot-box-height background-opac">
              <v-card
                class="bot-box-height"
                outlined
                tile
                style="border-left-color: #1993c9"
              >
                <v-card-text class="card-6">
                  <p>Send me notifications</p>
                  <v-radio-group v-model="user_settings.send_me_notifications">
                    <v-radio
                      label="On New Event"
                      value="on_new_event"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="Daily"
                      value="daily"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="Weekly"
                      value="weekly"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                    <v-radio
                      label="Monthly"
                      value="monthly"
                      color="#61bfeb"
                      :ripple="false"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top: 25px" no-gutters class="bottom-buttons">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" class="text-right">
              <v-btn
                :ripple="false"
                class="button-revert"
                @click="revert"
                style="margin-right: 30px"
                ><v-img
                  alt="Revert"
                  class="shrink"
                  contain
                  src="../../assets/pages/button_revert.svg"
                  transition="scale-transition"
                  eager
                ></v-img
              ></v-btn>
              <v-btn
                :ripple="false"
                :disabled="!valid"
                type="submit"
                class="button-revert"
              >
                <v-img
                  alt="Save"
                  class="shrink"
                  contain
                  src="../../assets/pages/button_save.svg"
                  transition="scale-transition"
                  eager
                ></v-img>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="1" md="0" sm="0" />
    </v-row>
    <v-dialog v-model="dialog" max-width="800" content-class="user-dialog">
      <v-card id="user-dialog">
        <v-card-title>CHANGE PASSWORD</v-card-title>
        <v-card-text>
          <v-form ref="editForm" @submit.prevent="submitEdit" v-model="editValid">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-right pr-2 pt-4">
                <span>Old Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="oldPassword"
                  required
                  :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOldPass ? 'text' : 'password'"
                  @click:append="showOldPass = !showOldPass"
                  autocomplete="off"
                  :rules="passwordRules"
                  clearable
                  outlined
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-right pr-2 pt-4">
                <span>New Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="newPassword"
                  required
                  :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPass ? 'text' : 'password'"
                  @click:append="showNewPass = !showNewPass"
                  autocomplete="off"
                  :rules="passwordRules"
                  clearable
                  outlined
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-right pr-2 pt-4">
                <span>Confirm Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="ConfirmPassword"
                  required
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  autocomplete="off"
                  :rules="confirmPassRules"
                  clearable
                  outlined
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters style="margin-top: 20px">
              <v-col cols="12" md="12">
                <span>Are you sure you want to change the password?</span>
              </v-col>
            </v-row>
            <v-row no-gutters style="margin-top: 10px">
              <v-spacer></v-spacer>
              <v-col cols="12" md="4" class="text-right">
                <v-btn
                  style="margin-right: 30px; background-color: #393939"
                  class="form-btn"
                  @click="closeDialog"
                  >CANCEL</v-btn
                >
                <v-btn
                  :ripple="false"
                  class="form-btn"
                  type="submit"
                  :disabled="!editValid"
                  >SAVE</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" md="9" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn class="form-btn" :ripple="false" @click="closeMessage">OK</v-btn>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="../../assets/css/user-profile.scss"></style>
<script>
import moment from "moment";
import {
  userProfileService,
  getImageUrl,
  formatPhoneNum,
  getTimeZoneOffset,
  getTimeZoneName,
  getOS,
} from "../../services";
export default {
  data() {
    return {
      userName: "",
      userGroup: "",
      userSite: "",
      userFullName: "",
      lastLogin: "",
      lastDeviceLogin: "",
      emailAddress: "",
      phoneNum: "",
      profileImage: "",
      oldPassword: "",
      newPassword: "",
      ConfirmPassword: "",
      dialog: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmPassword: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v?.length >= 8 || "Password length must be greater than 7",
      ],
      user_settings: {
        on_screen_notifications: true,
        email_notifications: true,
        sms_notifications: true,
        notify_me_for: "everything", // new_alarm_triggers, new_alarm_escalations, grid_services_requests
        send_me_notifications: "on_new_event", // daily, weekly, monthly
      },
      userProfile: {},
      valid: false,
      editValid: false,
      userNameRules: [(v) => !!v || "User Full Name is required"],
      avatarImage: null,
      fileRule: [
        (value) =>
          !value || value.size <= 2000000 || "Avatar size should be less than 2 MB!",
      ],
      timeZoneOffset: getTimeZoneOffset(),
      timeZoneName: getTimeZoneName(),
    };
  },
  created() {
    userProfileService.getCurrentUserProfile().then((res) => {
      if (res?.data?.data) {
        this.setUserProfile(res.data.data);
      }
    });
  },
  computed: {
    confirmPassRules() {
      return [this.ConfirmPassword === this.newPassword || "Password must match"];
    },
    deviceLogin() {
      let browserName = this.$browserDetect.meta.name;
      if (
        window.navigator.userAgent.indexOf("Edge") > -1 ||
        window.navigator.userAgent.indexOf("Edg") > -1
      ) {
        browserName = "Edge";
      }
      const device = `${getOS()}/${browserName}`;
      return device;
    },
  },
  methods: {
    setUserProfile(data) {
      this.userName = data.full_name || "";
      this.userGroup = data.user_group?.name || "";
      this.userSite = data.user_site || "N/A";
      this.userFullName = data.full_name || "";
      this.valid = !!data.full_name;
      this.lastLogin = data.last_login
        ? moment(data.lastLogin).format("DD-MM-YYY HH:mm:ss")
        : "Active Now";
      // this.lastDeviceLogin = this.deviceLogin; //data.last_device_login || "N/A";
      this.emailAddress = data.email || "";
      this.phoneNum = formatPhoneNum(data.phone_number);
      if (data.user_settings) {
        this.user_settings = Object.assign({}, this.user_settings, data.user_settings);
      }
      this.userProfile = Object.assign({}, this.userProfile, data);
      this.profileImage = data.profile_image ? getImageUrl(data.profile_image) : "";
    },
    revert() {
      this.setUserProfile(this.userProfile);
      this.showOldPass = false;
      this.showNewPass = false;
      this.showConfirmPassword = false;
    },
    submit() {
      let url = "";
      if (this.profileImage?.indexOf("data:image") > -1) {
        url = this.profileImage.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
      }
      const data = {
        full_name: this.userName,
        phone_number: this.phoneNum,
        email: this.emailAddress,
        profile_image: url,
        user_settings: this.user_settings,
      };
      return userProfileService
        .setUserProfile(data)
        .then((res) => {
          if (res?.data?.data) {
            this.setUserProfile(res.data.data);
            this.apiMessage = "User's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
          }
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    onFileChange(payload) {
      const file = payload; // in case vuetify file input
      if (file && file.size <= 2000000) {
        this.imageToBase64(file);
      } else {
        this.profileImage = "";
      }
    },
    triggerFileChange() {
      this.$refs.file.$refs.input.click();
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profileImage = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        this.profileImage = "";
      };
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.editForm.reset();
    },
    submitEdit() {
      const data = {
        current_password: this.oldPassword,
        new_password: this.newPassword,
      };
      userProfileService
        .updateUserPassword(data)
        .then(() => {
          this.$refs.editForm.reset();
          this.dialog = false;
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
};
</script>
